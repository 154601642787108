
import { defineComponent, PropType } from 'vue'
import {
	IBanner,
	BannerStatus,
} from '@myndshft/web-components/lib/notification-banner/types'

import { MyndshftPanel } from '@myndshft/panel'
import ModifiedbyTooltip from '@/components/worklist/shared/modifiedby-tooltip.vue'

enum BannerTableType {
	INFO = 'Info',
	SERVICE_UNAVAILABLE = 'Service Unavailable',
	ONGOING_ISSUE = 'Ongoing Issue',
}

export default defineComponent({
	name: 'user-management-list',
	components: {
		MyndshftPanel,
		ModifiedbyTooltip,
	},
	props: {
		isLoading: Boolean,
		banners: { type: Array as PropType<IBanner[]>, default: () => [] },
	},
	setup() {
		const columns = [
			{ text: 'Message', value: 'message', sortable: false },
			{ text: 'Schedule', value: 'schedule', sortable: false, width: '240px' },
			{ text: 'Type', value: 'type', width: '150px' },
			{ text: 'Product', value: 'product', width: '155px' },
			{
				text: '',
				value: 'menu',
				sortable: false,
				width: '155px',
			},
		]

		return { BannerStatus, BannerTableType, columns }
	},
})
