var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[_c('myndshft-panel',[_c('h2',[_vm._v("Previous Notifications")]),_c('v-data-table',{staticClass:"elevation-0 data-table",attrs:{"headers":_vm.columns,"items":_vm.banners,"loading":_vm.isLoading,"fixed-header":""},scopedSlots:_vm._u([{key:`item.message`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.message)+" ")]}},{key:`item.schedule`,fn:function({ item }){return [_c('div',{staticClass:"schedule"},[_c('div',[_c('strong',[_vm._v("S: ")]),_vm._v(_vm._s(new Date(item.startDateTime).toLocaleString())+" ")]),_c('div',[_c('strong',[_vm._v("E: ")]),_vm._v(_vm._s(new Date(item.endDateTime).toLocaleString())+" ")])])]}},{key:`item.type`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.BannerTableType[item.type])+" ")]}},{key:`item.product`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.product)+" ")]}},{key:`item.menu`,fn:function({ item }){return [_c('div',{staticClass:"row-control"},[_c('modifiedby-tooltip',{staticClass:"modifiedby-icon",attrs:{"name":item.meta.updatedBy,"displayDatetime":new Date(item.meta.updatedAt).toLocaleString()}}),_c('button',{class:{
							'icon-button': true,
							'not-allowed': !_vm.$canEditBanner(),
							'is-loading': _vm.isLoading,
						},attrs:{"id":"banner-active-button","disabled":!_vm.$canEditBanner()},on:{"click":function($event){return _vm.$emit('status', item)}}},[_c('fa-icon',{class:{
								disabled: !_vm.$canEditBanner(),
								active: item.status === _vm.BannerStatus.ACTIVE,
							},attrs:{"icon":item.status === _vm.BannerStatus.ACTIVE ? 'eye' : 'eye-slash'}})],1),_c('button',{class:{
							'icon-button': true,
							'not-allowed': !_vm.$canEditBanner(),
							'is-loading': _vm.isLoading,
						},attrs:{"id":"banner-edit-button","disabled":!_vm.$canEditBanner()},on:{"click":function($event){return _vm.$emit('edit', item)}}},[_c('fa-icon',{class:{ disabled: !_vm.$canEditBanner() },attrs:{"icon":"edit"}})],1),_c('button',{class:{
							'icon-button': true,
							'not-allowed': !_vm.$canDeleteBanner(),
							'is-loading': _vm.isLoading,
						},attrs:{"id":"banner-delete-button","disabled":!_vm.$canDeleteBanner()},on:{"click":function($event){return _vm.$emit('delete', item)}}},[_c('fa-icon',{class:{ disabled: !_vm.$canDeleteBanner() },attrs:{"icon":"trash-alt"}})],1)],1)]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }