import axios from 'axios'
import { IBanner } from '@myndshft/web-components/lib/notification-banner/types'

import { getBff } from '@/service/shared.http'

function formatBannerTime(banner: any) {
	const formatted: any = {}
	Object.keys(banner).forEach(key => {
		if (Object.prototype.hasOwnProperty.call(banner, key)) {
			switch (key) {
				case 'startDateTime':
				case 'endDateTime':
					formatted[key] = new Date(banner[key] as string).toISOString()
					break
				default:
					formatted[key] = banner[key]
			}
		}
	})
	return formatted
}

export async function fetchBanners(): Promise<IBanner[]> {
	const path = `${getBff()}/banners`
	const response = await axios.get(path)
	return response.data
}

export async function createBanner(banner: IBanner): Promise<IBanner> {
	const path = `${getBff()}/banners`
	const formattedBanner = formatBannerTime(banner)
	const response = await axios.post(path, { banner: formattedBanner })
	return response.data
}

export async function updateBannerDetails(
	bannerId: string,
	updates: any,
	updateMask: string[]
): Promise<IBanner> {
	const path = `${getBff()}/banners/${bannerId}`
	const formattedUpdates = formatBannerTime(updates)
	const response = await axios.patch(path, {
		updates: formattedUpdates,
		updateMask,
	})
	return response.data
}

export async function deleteBanner(bannerId: string): Promise<any> {
	const path = `${getBff()}/banners/${bannerId}`
	const response = await axios.delete(path)
	return response.data
}
