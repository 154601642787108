
import { defineComponent, ref, watch, PropType } from 'vue'
import {
	IBanner,
	BannerType,
	BannerProduct,
} from '@myndshft/web-components/lib/notification-banner/types'
import { bannerPreMessageDisplay } from '@myndshft/web-components/lib/notification-banner/utils'

import { MyndshftPanel } from '@myndshft/panel'

export default defineComponent({
	name: 'notification-configuration',
	components: {
		MyndshftPanel,
	},
	props: {
		banner: Object as PropType<IBanner>,
	},
	setup(props, context) {
		const fieldLabels = {
			type: 'Notifcation Type',
			product: 'Notification Product',
			start: 'Start Time',
			end: 'End Time',
			message: 'Notification Message',
		}
		const typeOptions = [
			{ title: 'Info', value: BannerType.INFO },
			{ title: 'Ongoing Issue', value: BannerType.ONGOING_ISSUE },
			{ title: 'Service Unavailable', value: BannerType.SERVICE_UNAVAILABLE },
		]
		const productOptions = [
			{ title: 'All', value: BannerProduct.ALL },
			{ title: 'Myndview', value: BannerProduct.MYNDVIEW },
			{ title: 'PA-Submit', value: BannerProduct.PASUBMIT },
			{ title: 'PA-Central', value: BannerProduct.PACENTRAL, disabled: true },
			{
				title: 'Myndcontrol',
				value: BannerProduct.MYNDCONTROL,
				disabled: true,
			},
		]

		const showBannerPreview = ref(false)
		const bannerConfig = ref({ ...props.banner })
		const endDateMin = ref('')

		const handleSave = () => {
			context.emit('save', bannerConfig.value)
		}

		const handlePreview = () => {
			showBannerPreview.value = true
		}

		watch(
			() => props.banner,
			banner => {
				bannerConfig.value = { ...banner }
			}
		)

		watch(
			() => bannerConfig.value.startDateTime,
			start => {
				endDateMin.value = start || ''
			}
		)

		return {
			BannerType,
			bannerPreMessageDisplay,
			showBannerPreview,
			fieldLabels,
			typeOptions,
			productOptions,
			bannerConfig,
			endDateMin,
			handleSave,
			handlePreview,
		}
	},
})
