
import { defineComponent, ref, onBeforeMount } from 'vue'
import { Alert, toast } from '@/dependencies/sweet-alert'
import {
	IBanner,
	BannerType,
	BannerProduct,
	BannerStatus,
} from '@myndshft/web-components/lib/notification-banner/types'
import {
	fetchBanners,
	createBanner,
	updateBannerDetails,
	deleteBanner,
} from '@/service/notificationBanner.http'

import NotificationConfiguration from '@/components/notification-banner/configuration.vue'
import NotificationList from '@/components/notification-banner/list.vue'

export default defineComponent({
	components: {
		NotificationConfiguration,
		NotificationList,
	},
	setup() {
		const emptyBanner = (): IBanner => ({
			bannerId: '',
			type: BannerType.INFO,
			startDateTime: '',
			endDateTime: '',
			message: '',
			status: BannerStatus.ACTIVE,
			product: BannerProduct.ALL,
			meta: {
				createdAt: '',
				createdBy: '',
				updatedAt: '',
				updatedBy: '',
			},
		})
		const isLoading = ref(false)
		const selectedBanner = ref(emptyBanner())
		const bannerList = ref<IBanner[]>([])

		const getBanners = () => {
			isLoading.value = true
			fetchBanners()
				.then(banners => {
					bannerList.value = banners
				})
				.finally(() => {
					isLoading.value = false
				})
		}

		const handleReset = () => {
			selectedBanner.value = emptyBanner()
		}

		const formatDateForInput = (dateString: string) => {
			const date = new Date(dateString)
			const YYYY = date.getUTCFullYear()
			const MM = ('0' + (date.getMonth() + 1)).slice(-2)
			const DD = ('0' + date.getDate()).slice(-2)
			const hh = ('0' + date.getHours()).slice(-2)
			const mm = ('0' + date.getMinutes()).slice(-2)
			// YYYY-MM-DDThh:mm format for datetime-local input
			return `${YYYY}-${MM}-${DD}T${hh}:${mm}`
		}

		const handleEdit = (banner: IBanner) => {
			const formatted = {
				...banner,
				startDateTime: formatDateForInput(banner.startDateTime),
				endDateTime: formatDateForInput(banner.endDateTime),
			}
			selectedBanner.value = formatted
			window.scrollTo(0, 0)
		}

		const updateBanner = (banner: IBanner) => {
			const updateMask: string[] = []
			const updates: any = {}
			Object.keys(banner).forEach(key => {
				if (
					banner[key as keyof IBanner] !==
					selectedBanner.value[key as keyof IBanner]
				) {
					updates[key] = banner[key as keyof IBanner]
					updateMask.push(key)
				}
			})
			if (updateMask.length) {
				updateBannerDetails(banner.bannerId, updates, updateMask)
					.then(() => {
						toast({
							type: Alert.SUCCESS,
							title: `Successfully updated notification banner`,
							timer: 5000,
						})
						handleReset()
						getBanners()
					})
					.catch(error => {
						toast({
							type: Alert.ERROR,
							title:
								error.response.data.message ||
								`Failed to update notifcation banner`,
							timer: 5000,
						})
					})
			} else {
				toast({
					type: Alert.INFO,
					title: `No changes have been made to the banner`,
					timer: 5000,
				})
			}
		}

		const handleSave = (banner: IBanner) => {
			if (banner.bannerId) {
				updateBanner(banner)
			} else {
				createBanner(banner)
					.then(() => {
						toast({
							type: Alert.INFO,
							title: `Successfully created notification banner`,
							timer: 5000,
						})
						handleReset()
						getBanners()
					})
					.catch(error => {
						toast({
							type: Alert.ERROR,
							title:
								error.response.data.message ||
								`Failed to create notifcation banner`,
							timer: 5000,
						})
					})
			}
		}

		const handleStatus = (banner: IBanner) => {
			const updates: Partial<IBanner> = {}
			if (banner.status === BannerStatus.INACTIVE) {
				updates.status = BannerStatus.ACTIVE
			} else {
				updates.status = BannerStatus.INACTIVE
			}
			updateBannerDetails(banner.bannerId, updates, ['status'])
				.then(updatedBanner => {
					banner = Object.assign(banner, updatedBanner)
					toast({
						type: Alert.SUCCESS,
						title: `Notification banner status is now ${banner.status}`,
						timer: 5000,
					})
				})
				.catch(() => {
					toast({
						type: Alert.SUCCESS,
						title: `Unable to change notifcation banner status`,
						timer: 5000,
					})
				})
		}

		const handleDelete = (banner: IBanner) => {
			deleteBanner(banner.bannerId)
				.then(() => {
					toast({
						type: Alert.SUCCESS,
						title: `Successfully removed notification banner`,
						timer: 5000,
					})
					handleReset()
					getBanners()
				})
				.catch(error => {
					toast({
						type: Alert.ERROR,
						title:
							error.response.data.message ||
							`Unable to remove notification banner`,
						timer: 5000,
					})
				})
		}

		onBeforeMount(() => {
			getBanners()
		})

		return {
			isLoading,
			selectedBanner,
			bannerList,
			handleReset,
			handleEdit,
			handleSave,
			handleStatus,
			handleDelete,
		}
	},
})
